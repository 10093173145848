// Screen sizes
const sizes = {
    tn: '270px',
    sm: '320px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xlg: '1400px',
  };
  
// Breakpoints based on the config
const BreakPoints = {
    tn: `@media (min-width: ${sizes.tn})`,
    sm: `@media (min-width: ${sizes.sm})`,
    md: `@media (min-width: ${sizes.md})`,
    lg: `@media (min-width: ${sizes.lg})`,
    xl: `@media (min-width: ${sizes.xl})`,
    xlg: `@media (min-width: ${sizes.xlg})`,
};

export default BreakPoints;